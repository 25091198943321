import React from "react";

import { useSelector } from "react-redux";

import "./Poster.css";

const Poster = () => {

    const isMobile = useSelector(state => state.device.isMobile);

    function mobilizeClass(baseClass) {
        let mobilizeClass = baseClass;
        if (isMobile) {
            mobilizeClass += " " + baseClass + "-mobile";
        }
        return mobilizeClass;
    }


    return (
        <div className="poster">
            <div className="poster-image">
                <img src="media/Main1080.webp" alt="Poster" />
            </div>
            <div className="poster-text-container">
                <div className="poster-text-spacer" />
                <div className="poster-text">
                    <h1>Improv at Happy Valley</h1>
                    <h2>February 20th</h2>
                    <div id={mobilizeClass("poster-times")}>
                        <div>
                        <h3>Doors at 7:30</h3>
                        </div>
                        <div>
                        <h3>Show at 8:00</h3>
                        </div>
                        <div>
                        <h3>Jam at 9:00</h3>
                        </div>
                    </div>
                    <div id="poster-address">
                        <h4>Happy Valley Arcade Bar</h4>
                        <h4>296 Main Street Beacon, NY</h4>
                    </div>
                </div>
                <div className="poster-text-spacer" />
            </div>
        </div>
    );
}

export default Poster;