import React, {
  
 } from "react";

import { useSelector } from 'react-redux';

 import { useNavigate } from 'react-router-dom';

import './Return.css';

const Return = () => {

    const navigate = useNavigate();

    const handleImageClick = () => {
        navigate('/');
    };

    const className = (baseName) => {
        return isMobile ? `${baseName}-mobile` : baseName;
    }

    const { isMobile } = useSelector(state => state.device);

      return (
        <div className="return-container">
          <h1 >You're all set.</h1>
          <p className={className('p')}>
            Check your email for a purchase confirmation, and simply identify yourself at the door on the night of the show.
          </p>
          <p className={className('p')}>
            Add a <a href="https://calendar.app.google/198k4UVUALm9exKm8"
              target="_blank"
              rel="noopener noreferrer"
            >reminder to your calendar
            </a>, and we'll see you there!
          </p>
          <p className={className('p')} id="contact">
            If you have any questions, please <a href="mailto:producedbythecore+questions@gmail.com">email us.</a>
          </p>
          <div 
          className="poster-image"
          onClick={handleImageClick} 
          style={{ cursor: 'pointer' }}
          >
                <img src="media/Main1080.webp" alt="Poster" />
            </div>
        </div>
      )
    }
  
  //   return null;
  // }

  export default Return;